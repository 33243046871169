<template>
    <painel :titulo="`Cobrança PIX - ${cobrancaPix?.cobrancaPixId}`" icone="pi pi-money-bill" :refreshFunction="obterCobrancaPix">
        <div class="mb-4">
            <TabView v-model:activeIndex="activeIndex">
                <TabPanel header="Dados Gerais">
                    <dados-gerais :cobrancaPix="cobrancaPix"></dados-gerais>
                </TabPanel>
                <TabPanel header="Solicitação Recebida">
                    <vue-json-pretty v-if="cobrancaPix" :data="JSON.parse(cobrancaPix?.objetoSolicitacao)" :showDoubleQuotes="true"></vue-json-pretty>
                </TabPanel>
                <TabPanel header="Objeto Integração" v-if="cobrancaPix && cobrancaPix.objetoIntegracao">
                    <vue-json-pretty
                        v-if="cobrancaPix?.objetoIntegracao.isJson()"
                        :data="JSON.parse(cobrancaPix?.objetoIntegracao)"
                        :showDoubleQuotes="true"
                    ></vue-json-pretty>
                    <span v-if="!cobrancaPix?.objetoIntegracao.isJson()"
                        ><strong>{{ cobrancaPix?.objetoIntegracao }}</strong></span
                    >
                </TabPanel>
                <TabPanel header="Retorno Integração" v-if="cobrancaPix && cobrancaPix.objetoRetorno">
                    <vue-json-pretty
                        v-if="cobrancaPix?.objetoRetorno.isJson()"
                        :data="JSON.parse(cobrancaPix?.objetoRetorno)"
                        :showDoubleQuotes="true"
                    ></vue-json-pretty>
                    <span v-if="!cobrancaPix?.objetoRetorno.isJson()"
                        ><strong>{{ cobrancaPix?.objetoRetorno }}</strong></span
                    >
                </TabPanel>
            </TabView>
        </div>
        <btn-voltar :route="{ name: 'Pagamentos_Pix' }"></btn-voltar>
        <btn-atualizar-status-pix
            :cobrancaPix="cobrancaPix"
            @atualizarCobrancaPix="atualizarCobrancaPix($event)"
            v-if="mostrarBtnAtualizarStatusPix"
        ></btn-atualizar-status-pix>
    </painel>
</template>

<script>
import CobrancaPixService from './services';
import DadosGerais from './DadosGerais';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import BtnAtualizarStatusPix from './BtnAtualizarStatusPix';

export default {
    components: {
        DadosGerais,
        VueJsonPretty,
        BtnAtualizarStatusPix,
    },

    data() {
        return {
            cobrancaPix: null,
            activeIndex: 0,
        };
    },

    methods: {
        obterCobrancaPix() {
            this.$store.dispatch('addRequest');
            CobrancaPixService.obterPorId(this.$route.params.id).then((response) => {
                if (response?.success) {
                    this.cobrancaPix = response.data;
                } else {
                    this.cobrancaPix = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        atualizarCobrancaPix(cobrancaPix) {
            this.cobrancaPix = cobrancaPix;
        },
    },

    computed: {
        mostrarBtnAtualizarStatusPix() {
            return this.$temAcessoView('PAG-PIX-02') && this.cobrancaPix?.textoQRCode;
        },
    },

    mounted() {
        this.obterCobrancaPix();
    },
};
</script>
