<template>
    <Button label="Atualizar Status PIX" icon="pi pi-check-circle" @click="confirmar()" class="ml-2" />
</template>

<script>
import CobrancasPixService from './services';

export default {
    props: {
        cobrancaPix: {
            type: Object,
        },
    },

    emits: ['atualizarCobrancaPix'],

    methods: {
        confirmar() {
            this.$confirm.require({
                message: `Tem certeza que deseja atualizar o status do PIX?`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.atualizarStatusPix();
                },
            });
        },

        atualizarStatusPix() {
            this.$store.dispatch('addRequest');
            CobrancasPixService.atualizarStatusPix(this.cobrancaPix.cobrancaPixId).then((response) => {
                if (response?.success) {
                    this.$emit('atualizarCobrancaPix', response.data);
                    this.$toast.add({
                        severity: 'success',
                        summary: 'PIX',
                        detail: 'Cobrança PIX atualizada com sucesso',
                        life: 3000,
                    });
                    this.$store.dispatch('setAtualizar', true);
                } else {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'PIX',
                        detail: `${response.error[0]}`,
                        life: 3000,
                    });
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },
};
</script>
