import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_PAGAMENTOS_PORT}${process.env.VUE_APP_API_PATH}/pagamentos`;

export default {
    obterTodas(filtro) {
        return axiosJwt.get(`${api}/pix?dataSolicitacaoDe=${filtro?.dataSolicitacaoDe}&dataSolicitacaoAte=${filtro?.dataSolicitacaoAte}`);
    },

    obterPorId(id) {
        return axiosJwt.get(`${api}/pix/${id}`);
    },

    atualizarStatusPix(cobrancaPixId) {
        return axiosJwt.patch(`${api}/pix/${cobrancaPixId}/atualizarstatuspix`);
    }
};
