<template>
    <detalhe titulo="Id" :size="size">
        {{ cobrancaPix?.cobrancaPixId }}
    </detalhe>
    <detalhe titulo="Solicitante" :size="size">
        {{ cobrancaPix?.usuarioSolicitacao }}
    </detalhe>
    <detalhe titulo="Identificador" :size="size">
        {{ cobrancaPix?.clienteId }}
    </detalhe>
    <detalhe titulo="Descrição da Cobrança" :size="size">
        {{ cobrancaPix?.descricaoCobranca }}
    </detalhe>
    <detalhe titulo="Valor" :size="size">
        <span>{{ $numeral(cobrancaPix?.valor).format('$0,0.00') }}</span>
    </detalhe>
    <detalhe titulo="Conta" :size="size">
        <span v-if="cobrancaPix?.conta">{{ cobrancaPix?.conta?.descricao }}</span>
        <span v-if="!cobrancaPix?.conta">N/A</span>
    </detalhe>
    <detalhe titulo="Status PIX" :size="size">
        <status :status="cobrancaPix?.statusPix"></status>
    </detalhe>
    <detalhe titulo="Status" :size="size">
        <status :status="cobrancaPix?.statusDescr"></status>
    </detalhe>
    <detalhe titulo="Solicitado em" :size="size">
        {{ $dateFormat(cobrancaPix?.dataSolicitacao, 'DD/MM/YYYY HH:mm:ss') }}
    </detalhe>
    <detalhe titulo="Processado em" :size="size">
        {{ $dateFormat(cobrancaPix?.dataIntegracao, 'DD/MM/YYYY HH:mm:ss') }}
    </detalhe>
    <detalhe titulo="Atualizado em" :size="size">
        {{ $dateFormat(cobrancaPix?.dataAtualizacao, 'DD/MM/YYYY HH:mm:ss') }}
    </detalhe>
    <detalhe titulo="Tempo Expiração" :size="size" v-if="cobrancaPix?.expiracaoPix">
        {{ cobrancaPix?.expiracaoPix }}
    </detalhe>
    <detalhe titulo="Texto QRCode" :size="size" v-if="cobrancaPix?.textoQRCode">
        {{ cobrancaPix?.textoQRCode }}
    </detalhe>
    <detalhe titulo="Imagem QRCode" :size="size" v-if="cobrancaPix?.imagemQRCode">
        <img width="300" height="300" :src="`data:image/PNG;base64,${cobrancaPix?.imagemQRCode}`" />
    </detalhe>
</template>

<script>
export default {
    props: {
        cobrancaPix: {
            type: Object,
        },
    },

    data() {
        return {
            size: 150,
        };
    },

    methods: {},
};
</script>
